<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_schedule')" :isFilter="false" :isColumns="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_schedule')" :isFilter="false" :isColumns="false" />
            </template>
            <div>
                <loading v-show="loading" />
                <div v-show="!loading">
                    <vue-cal :selected-date="startDate" :time-cell-height="30" :time-step="30"
                        :disable-views="['years', 'year', 'month', 'days', 'day']" :events="timetables"
                        :locale="$i18n.locale" :time-from="timeFrom" :time-to="timeTo" :on-event-click="showVueCalModal" />
                    <CommonModal ref="vueCalModal" size="sm">
                        <template v-slot:CommonModalTitle>
                            <i style="font-size: 24px;" class="ri-calendar-event-line"></i>
                        </template>
                        <template v-slot:CommonModalContent>
                            <div>
                                <b-row>
                                    <b-col md="12" class="mb-2">{{ $moment(selectedEvent.start).locale($i18n.locale).format("dddd") }}</b-col>
                                    <b-col md="12" class="mb-2">{{ selectedEvent.title }}</b-col>
                                    <b-col md="12" class="mb-2">{{ selectedEvent.start_time }} -
                                        {{ selectedEvent.end_time }}</b-col>
                                    <b-col md="12" v-html="selectedEvent.content"></b-col>
                                </b-row>
                            </div>
                        </template>
                    </CommonModal>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>

// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Components
import Loading from '@/components/elements/Loading'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import '/src/locales/tr/vuecal.js'
import CommonModal from "@/components/elements/CommonModal";

// Services
import CourseScheduleService from "@/services/CourseScheduleService";

// Other
import moment from "moment/moment";

export default {
    name: "MyCourseSchedule",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Loading,
        VueCal,
        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t("course_schedule"),
        }
    },
    data() {
        return {
            timetables: [],
            startDate: null,

            timeFrom: 7 * 60,
            timeTo: 24 * 60,

            loading: false,
            user: {},
            studentProgramId: null,
            selectedEvent: {}
        }
    },
    async mounted() {
        try {
            await this.getUser();
            this.startDate = moment().startOf('week').add(+1, 'days').format('YYYY-MM-DD');
            await this.getMySchedule()
        } catch (err) {
            this.showErrors(err)
        }
    },
    methods: {
        async getUser() {
            this.user = await this.$store.getters['auth/getUser'];
            this.studentProgramId = this.user.id;
        },
        async getMySchedule() {
            const randomColor = (() => {
                let r = Math.floor(Math.random() * 256);
                let g = Math.floor(Math.random() * 256);
                let b = Math.floor(Math.random() * 256);
                return `rgb(${r}, ${g}, ${b})`;
            })

            this.timetables = [];
            this.loading = true;
            CourseScheduleService.getOfStudent(this.studentProgramId)
                .then(response => {
                    let schedule = response.data.data.course_schedules;
                    let days = Object.values(schedule);
                    if (days.length) {
                        let dayNumber = 1;
                        Object.values(schedule)
                            .forEach((day) => {
                                if (day.length) {
                                    day.forEach((lesson, i) => {
                                        let date = moment().startOf('week').add(dayNumber, 'days').format('YYYY-MM-DD');
                                        this.timetables.push({
                                            start: date + ' ' + lesson.start_time,
                                            end: date + ' ' + lesson.end_time,
                                            title: this.getLocaleText(lesson, 'name'),
                                            content: this.getLocaleText(lesson, 'classroom_name'),
                                            style: 'background-color:' + randomColor()
                                        });
                                    })
                                }
                                dayNumber++;
                            })
                    }
                })
                .catch((e) => {
                    this.showErrors(e);
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        showVueCalModal(event, e) {
            this.selectedEvent = event
            moment.locale('tr')
            this.selectedEvent.date = moment(event.start).format("DD-MM-YYYY")
            this.selectedEvent.start_time = moment(event.start).format("LT")
            this.selectedEvent.end_time = moment(event.end).format("LT")
            this.$refs.vueCalModal.$refs.commonModal.show()
            e.stopPropagation()
        }
    }
}
</script>

<style lang="scss">
.vuecal {
    border: 1px solid #e9ecef;
    box-shadow: none;
}

.vuecal__menu {
    background: none;
    border-bottom: 1px solid #e9ecef;
}

.vuecal__weekdays-headings,
.vuecal__all-day {
    background: #e9ecef;
}

.vuecal__event {
    background-color: #e9ecef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #cccccc;
}

.vuecal__cell--selected {
    background: none !important;
}

.vuecal__cell--current,
.vuecal__cell--today {
    background: none !important;
}

.vuecal__flex .weekday-label span:last-child {
    display: none;
}

.vuecal__view-btn--active {
    background: #e9ecef;
    border-bottom: 0;
}

.vuecal__event-title,
.vuecal__event-time,
.vuecal__event-content {
    font-size: 13px;
    font-weight: 500;
}

@media screen and (max-width: 992px) {

    .vuecal__event-title,
    .vuecal__event-time,
    .vuecal__event-content {
        font-size: 10px;
        font-weight: 500;
    }
}

.vuecal__menu,
.vuecal__title-bar {
    display: none !important;
}

.vuecal__special-hours {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

}

.closed {
    background: #fff7f0 repeating-linear-gradient(-45deg,
            rgba(255, 162, 87, 0.25),
            rgba(255, 162, 87, 0.25) 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px);
    color: #f6984c;
}
</style>
